import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { downloadQrCode as downloadQrCodeApi } from "services/apis/admin";

export const downloadQrCode = createAsyncThunk("qrcode/download", async ({ downloadQr, token }: any, thunkAPI) => {
  try {
    const response = await downloadQrCodeApi(token, downloadQr);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface DownloadQrState {
  downloadUrl: string | null;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: DownloadQrState = {
  downloadUrl: null,
  status: "idle",
  error: null,
};

const downloadQrSlice = createSlice({
  name: "downloadQr",
  initialState,
  reducers: {
    resetDownloadQrStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.downloadUrl = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadQrCode.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadQrCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.downloadUrl = action.payload;
      })
      .addCase(downloadQrCode.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetDownloadQrStatus } = downloadQrSlice.actions;
export default downloadQrSlice.reducer;
