import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { downloadQrsById as downloadQrsByIdApi } from "services/apis/admin";

export const downloadQrsById = createAsyncThunk("qrcode/downloadQrsById", async ({ token, qr_ids }: any, thunkAPI) => {
  try {
    const formData = new FormData();
    formData.append("qr_ids", qr_ids);
    const response = await downloadQrsByIdApi(token, formData);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface DownloadQrsByIdState {
  downloadUrls: any;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: DownloadQrsByIdState = {
  downloadUrls: [],
  status: "idle",
  error: null,
};

const downloadQrsByIdSlice = createSlice({
  name: "downloadQrsById",
  initialState,
  reducers: {
    resetDownloadQrsByIdStatus: (state) => {
      state.status = "idle";
      state.error = null;
      state.downloadUrls = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadQrsById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(downloadQrsById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.downloadUrls = action.payload;
      })
      .addCase(downloadQrsById.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetDownloadQrsByIdStatus } = downloadQrsByIdSlice.actions;
export default downloadQrsByIdSlice.reducer;
