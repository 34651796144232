import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { approveRedeemVerification as approveRedeemVerificationApi } from "services/apis/admin";

export const approveRedeemVerification = createAsyncThunk(
  "redeemVerification/approve",
  async ({ request_id, token }: any, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append("request_id", request_id);
      const response = await approveRedeemVerificationApi(formData, token);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface ApproveRedeemVerificationsState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ApproveRedeemVerificationsState = {
  status: "idle",
  error: null,
};

const approveRedeemVerificationsSlice = createSlice({
  name: "approveRedeemVerifications",
  initialState,
  reducers: {
    resetApproveRedeemVerificationsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(approveRedeemVerification.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveRedeemVerification.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(approveRedeemVerification.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetApproveRedeemVerificationsStatus } = approveRedeemVerificationsSlice.actions;
export default approveRedeemVerificationsSlice.reducer;
