import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createPromotion } from "services/apis/admin";

export const addPromotion = createAsyncThunk("promotion/add", async ({ token, promotionData }: any, thunkAPI) => {
  try {
    const formData = new FormData();
    // for (const [key, value] of Object.entries(promotionData)) {
    //   if (value !== undefined && value !== null && value !== "") {
    //     formData.append(key, value);
    //   }
    // }
    for (const [key, value] of Object.entries(promotionData)) {
      if (value !== undefined && value !== null) {
        if (typeof value === "object" && !(value instanceof Blob)) {
          // If the value is an object and not a Blob, convert it to a JSON string
          formData.append(key, JSON.stringify(value));
        } else {
          // Explicitly define the types for the value variable
          formData.append(key, value as string | Blob);
        }
      }
    }
    const { data } = await createPromotion(token, formData);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface CreatePromotionState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: CreatePromotionState = {
  status: "idle",
  error: null,
};

const createPromotionSlice = createSlice({
  name: "createPromotion",
  initialState,
  reducers: {
    resetCreatePromotionStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPromotion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addPromotion.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(addPromotion.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetCreatePromotionStatus } = createPromotionSlice.actions;
export default createPromotionSlice.reducer;
