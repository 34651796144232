import axios from "axios";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_Base_URL}`,
});

const config = (token: any) => {
  return {
    headers: {
      Authorization: `Bearer ` + token,
    },
  };
};

export const adminLogin = (formData: any) => api.post("/accounts/admin/login/", formData);
export const getAllUsers = (token: string, currentPage: any, username: any) => {
  let url = `/accounts/users?page=${currentPage}`;
  if (username) {
    url += `&username=${username}`;
  }
  return api.get(url, config(token));
};

export const restrictUserAccess = (token: string, formData: any) =>
  api.post("/accounts/restrict-user", formData, config(token));

export const activateUserAccount = (token: string, formData: any) =>
  api.post("/accounts/activate-user", formData, config(token));

export const deleteUserAccount = (token: string, formData: any) =>
  api.post("/accounts/delete-user", formData, config(token));

export const getUserDetailsById = (token: string, userId: any) => api.get(`/accounts/users/${userId}/`, config(token));

export const getPromotions = (token: string, currentPage: any, userType: any, isMobile: boolean) => {
  let apiUrl = `/manage-qr/promotions/?page=${currentPage}`;
  if (userType !== undefined) {
    apiUrl += `&promo_type=${userType}`;
  }
  if (isMobile) {
    apiUrl += `&page_size=${8}`;
  }
  return api.get(apiUrl, config(token));
};

export const createPromotion = (token: string, formData: any) =>
  api.post(`/manage-qr/promotions/`, formData, config(token));

export const updatePromotion = (token: string, formData: any, promotionId: any) =>
  api.patch(`/manage-qr/promotions/${promotionId}/`, formData, config(token));

export const generateQrImage = (token: string, formData: any) => api.post(`/manage-qr/qr/`, formData, config(token));

export const adjustPoints = (token: string, formData: any) =>
  api.post(`/manage-qr/adjust-point/`, formData, config(token));

export const getAllQrs = (token: string, currentPage: any, userType: any, qrStatus: any) => {
  let apiUrl = `/manage-qr/all?page=${currentPage}`;

  if (userType !== undefined) {
    apiUrl += `&type=${userType}`;
  }
  if (qrStatus === true) {
    apiUrl += `&used=true`;
  }
  return api.get(apiUrl, config(token));
};

export const getPendingRedeemVerifications = (token: string, currentPage: any, userType: any) => {
  let apiUrl = `/manage-qr/redeemed-verifications?page=${currentPage}`;

  if (userType !== undefined) {
    apiUrl += `&req_type=${userType}`;
  }
  return api.get(apiUrl, config(token));
};

export const approveRedeemVerification = (formData: any, token: string) => {
  let apiUrl = `/manage-qr/approve-redeem-request/`;
  return api.post(apiUrl, formData, config(token));
};

export const rejectRedeemVerification = (formData: any, token: string) => {
  let apiUrl = `/manage-qr/reject-redeem-request/`;
  return api.post(apiUrl, formData, config(token));
};

export const downloadQrCode = (token: string, downloadQr: any) => {
  let apiUrl = `/manage-qr/download-qr`;
  return api.get(apiUrl, { ...configForDownloadQrCode(token, downloadQr), responseType: "blob" });
};

export const downloadQrsById = (token: any, formData: any) => {
  let apiUrl = `/manage-qr/download-qr-by-ids`;
  return api.post(apiUrl, formData, { ...config(token), responseType: "blob" });
};

export const updateQr = (token: any, qrData: any) => {
  let apiUrl = `/manage-qr/update-qr`;
  const formData = new FormData();
  // Filter out fields with initial values
  const filteredQrData: any = Object.fromEntries(
    Object.entries(qrData).filter(([key, value]) => {
      // Only include fields that are not undefined or empty strings
      return value !== undefined && value !== "";
    })
  );

  // Append filtered data to FormData
  for (const key in filteredQrData) {
    formData.append(key, filteredQrData[key]);
  }
  return api.post(apiUrl, formData, { ...config(token) });
};

interface DownloadQrState {
  type?: string;
  include_expired?: boolean;
  used?: string;
  start_date?: string | null;
  end_date?: string | null;
  serial_start?: string;
  serial_end?: string;
}

const configForDownloadQrCode = (token: string, downloadQr: DownloadQrState) => {
  let params: any = {};

  // Comparison function to check if the value has changed from the initial state
  const hasChanged = (key: keyof DownloadQrState) =>
    downloadQr[key] !== undefined && downloadQr[key] !== false && downloadQr[key] !== null && downloadQr[key] !== "";

  if (hasChanged("type")) params["type"] = downloadQr.type;
  if (hasChanged("start_date")) params["start_date"] = downloadQr.start_date;
  if (hasChanged("end_date")) params["end_date"] = downloadQr.end_date;
  if (hasChanged("serial_start")) params["serial_start"] = downloadQr.serial_start;
  if (hasChanged("serial_end")) params["serial_end"] = downloadQr.serial_end;
  params["include_expired"] = downloadQr.include_expired;
  params["used"] = downloadQr.used;
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      // responseType: "blob",
    },
    params: params,
  };
};

export const createBrandingImage = (token: any, formData: any) => {
  let apiUrl = `branding/update-branding-images/`;
  return api.post(apiUrl, formData, config(token));
};

export const getBrandingImage = () => {
  let apiUrl = `/branding/branding-images/`;
  return api.get(apiUrl);
};

export const deletePromotion = (token: any, id: any) => {
  let apiUrl = `/manage-qr/promotions/${id}/`;
  return api.delete(apiUrl, config(token));
};
