import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getPromotions } from "services/apis/admin";

export const fetchPromotions = createAsyncThunk(
  "promotion/fetchAll",
  async ({ token, currentPage, userType, isMobile }: any, thunkAPI) => {
    try {
      const { data } = await getPromotions(token, currentPage, userType, isMobile);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface PromotionsState {
  promotions: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PromotionsState = {
  promotions: [],
  status: "idle",
  error: null,
};

const getPromotionsSlice = createSlice({
  name: "getPromotions",
  initialState,
  reducers: {
    resetPromotionsStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromotions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPromotions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.promotions = action.payload;
      })
      .addCase(fetchPromotions.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetPromotionsStatus } = getPromotionsSlice.actions;
export default getPromotionsSlice.reducer;
