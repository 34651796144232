import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getBrandingImage as getBrandingImageApi } from "services/apis/admin";

export const getBrandingImage = createAsyncThunk("brandingImage/getBrandingImage", async (_: any, thunkAPI) => {
  try {
    const response = await getBrandingImageApi();
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface GetBrandingImageState {
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  brandingImageData: any;
}

const initialState: GetBrandingImageState = {
  status: "idle",
  error: null,
  brandingImageData: [],
};

const getBrandingImageSlice = createSlice({
  name: "getBrandingImage",
  initialState,
  reducers: {
    resetGetBrandingImageStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrandingImage.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getBrandingImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.brandingImageData = action.payload;
      })
      .addCase(getBrandingImage.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetGetBrandingImageStatus } = getBrandingImageSlice.actions;
export default getBrandingImageSlice.reducer;
