import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "services/apis/admin";

export const fetchAllUsers = createAsyncThunk(
  "user/fetchAll",
  async ({ token, currentPage, username }: any, thunkAPI) => {
    try {
      const { data } = await getAllUsers(token, currentPage, username);
      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface AllUsersState {
  users: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: AllUsersState = {
  users: [],
  status: "idle",
  error: null,
};

const allUsersSlice = createSlice({
  name: "allUsers",
  initialState,
  reducers: {
    resetAllUserStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(fetchAllUsers.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});
export const { resetAllUserStatus } = allUsersSlice.actions;
export default allUsersSlice.reducer;
