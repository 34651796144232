import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deletePromotion } from "services/apis/admin";

export const deletePromotionById = createAsyncThunk(
  "promotion/delete",
  async ({ promotionId, token }: any, thunkAPI) => {
    try {
      await deletePromotion(token, promotionId);
      return promotionId; // Return the ID of the deleted promotion
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

interface PromotionsState {
  promotions: any[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PromotionsState = {
  promotions: [],
  status: "idle",
  error: null,
};

const deletePromotionSlice = createSlice({
  name: "deletePromotion",
  initialState,
  reducers: {
    resetDeletePromotionStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(deletePromotionById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletePromotionById.fulfilled, (state, action) => {
        state.status = "succeeded";
        // Remove the deleted promotion from the state
        state.promotions = state.promotions.filter((promotion) => promotion.id !== action.payload);
      })
      .addCase(deletePromotionById.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetDeletePromotionStatus } = deletePromotionSlice.actions;
export default deletePromotionSlice.reducer;
