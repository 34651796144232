import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminLogin } from "services/apis/admin";

export const adminLoginUser = createAsyncThunk("adminAuth/login", async (loginData: any, thunkAPI) => {
  try {
    const formData = new FormData();
    for (const key in loginData) {
      formData.append(key, loginData[key]);
    }
    const { data } = await adminLogin(formData);
    return data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface adminLoginState {
  isLoggedIn: boolean;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
  admin: any;
}

const initialAdminState: adminLoginState = {
  isLoggedIn: false,
  status: "idle",
  error: null,
  admin: null,
};

const adminLoginSlice = createSlice({
  name: "adminLogin",
  initialState: initialAdminState,
  reducers: {
    resetAdminStatus: (state) => {
      state.status = "idle";
      state.error = null;
    },
    adminLogout: (state) => {
      state.admin = null;
      state.isLoggedIn = false;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLoginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(adminLoginUser.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.status = "succeeded";
        state.admin = action.payload;
      })
      .addCase(adminLoginUser.rejected, (state, action: any) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetAdminStatus, adminLogout } = adminLoginSlice.actions;
export default adminLoginSlice.reducer;
